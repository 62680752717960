import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Item, itemTypes } from '../../types/Item';
import ConfigServiceAPI from '../../utils/api/configServiceAPI';
import { FailedDeletionMessages } from '../../types/Object';
import { ObjectFilter } from '../../utils/fnFilter';

export const fetchItems = createAsyncThunk<
    { items: Item[]; error: { message: string; code: string } | null; permissions?: any; totalResults?: number },
    {
        addPermissions?: boolean;
        projectId?: string;
        level?: number;
        pageSize?: number;
        pageNumber?: number;
        orderBy?: string;
        searchTerm?: string;
        filter?: ObjectFilter;
    }
>('items/fetchItems', async ({ addPermissions, projectId, level, pageSize, pageNumber, orderBy, searchTerm, filter }, thunkApi) => {
    const result = await ConfigServiceAPI.getItems(
        addPermissions,
        projectId,
        typeof level === 'number' ? level : 3,
        pageSize,
        pageNumber,
        orderBy,
        searchTerm,
        filter
    );

    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { items: result.response as any, error: null, permissions: result.permissions, totalResults: result.totalResults };
});

export const fetchAllItems = createAsyncThunk<
    { items: Item[]; error: { message: string; code: string } | null },
    {
        projectId?: string;
    }
>('items/fetchAllItems', async ({ projectId }, thunkApi) => {
    const result = await ConfigServiceAPI.getItems(undefined, projectId, 3);
    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { items: result.response as any, error: null };
});

export const fetchItem = createAsyncThunk<{ item: Item; error: { message: string; code: string } | null }, string>(
    'items/fetchItem',
    async (itemId, thunkApi) => {
        const result = await ConfigServiceAPI.getItemById(itemId);
        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { item: result.response as any as Item, error: null };
    }
);

export const fetchContentSourceTypes = createAsyncThunk<
    { contentSourceTypes: any[]; error: { message: string; code: string } | null },
    any | undefined
>('items/fetchContentSourceTypes', async ({ projectId }, thunkApi) => {
    const result = await ConfigServiceAPI.getContentSourceTypes(projectId);

    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { contentSourceTypes: result.response as any, error: null };
});

export const fetchItemTypes = createAsyncThunk<{ itemTypes: any[]; error: { message: string; code: string } | null }>(
    'items/itemTypes',
    async (_, thunkApi) => {
        const result = await ConfigServiceAPI.getItemTypes();

        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { itemTypes: result.response as any, error: null };
    }
);

export const fetchContentActionTypes = createAsyncThunk<{
    contentActionTypes: any[];
    actionTypeError: { message: string; code: string } | null;
}>('items/contentActionTypes', async (_, thunkApi) => {
    const result = await ConfigServiceAPI.getContentActionTypes();

    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { contentActionTypes: result.response as any, actionTypeError: null };
});

export const fetchDeeplinkOptions = createAsyncThunk<
    {
        deeplinkOptions: any[];
        error: { message: string; code: string } | null;
    },
    string
>('items/fetchDeeplinkOptions', async (projectId, thunkApi) => {
    const result = await ConfigServiceAPI.getDeepinkOptions(projectId);

    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { deeplinkOptions: result.response as any, error: null };
});

export const createItem = createAsyncThunk<{ id: string; error: { message: string; code: string } | null }, Item>(
    'items/createItem',
    async (item: Item, thunkApi) => {
        const result = await ConfigServiceAPI.createItem(item);

        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { id: result.response as any, error: null };
    }
);

export const updateItem = createAsyncThunk<
    { ok: boolean; error: { message: string; code: string } | null },
    { item: Item; shouldUnlockAfterSave?: boolean }
>('items/updateItem', async ({ item, shouldUnlockAfterSave }, thunkApi) => {
    const result = await ConfigServiceAPI.updateItem(item, shouldUnlockAfterSave);

    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { ok: !!result.response, error: null };
});

export const deleteItem = createAsyncThunk<{ ok: boolean; error: { message: string; code: string } | null }, string>(
    'items/deleteItem',
    async (id: string, thunkApi) => {
        const result = await ConfigServiceAPI.deleteItem(id);

        if (result.error || !result.response) {
            return thunkApi.rejectWithValue(result);
        }
        return { ok: !!result.response, error: null };
    }
);

export const deleteItemList = createAsyncThunk<
    { ok: boolean; error: { message: string; code: string } | null; failedDeletions: FailedDeletionMessages },
    string[]
>('items/deleteItemList', async (items, thunkApi) => {
    const result = await ConfigServiceAPI.deleteItemList(items);

    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }

    return { ok: !!result.response, error: null, failedDeletions: result.response as unknown as FailedDeletionMessages };
});

export const publishItem = createAsyncThunk<
    { ok: boolean; error: { message: string; code: string } | null },
    { itemId: string; published?: number }
>('items/publishItem', async ({ itemId }, thunkApi) => {
    const result = await ConfigServiceAPI.publishItem(itemId);
    if (result.error || !result.response) {
        return thunkApi.rejectWithValue(result);
    }
    return { ok: !!result.response, error: null };
});

export interface ItemState {
    items: Item[];
    allItems: Item[]; // this is needed for when we need to load all the items
    selectedItem?: Item;
    contentSourceTypes?: any[];
    storeItemTypes?: any[];
    contentActionTypes?: any[];
    itemPreview?: { itemId?: string; moduleId?: string; type?: itemTypes };
    totalResults?: number;
    loading: boolean;
    loadingAllItems: boolean;
    selectedItemLoading?: boolean;
    createOrModifyLoading?: boolean;
    error: {
        message: string;
        code: string;
        status?: number;
    } | null;
    actionTypeLoading?: boolean;
    actionTypeError?: {
        message: string;
        code: string;
        status?: number;
        data?: any;
    } | null;
    deeplinkOptionsLoading?: boolean;
    deeplinkOptions?: { label: string; value: string }[];
}

const initialState: ItemState = {
    items: [],
    allItems: [],
    loadingAllItems: false,
    loading: false,
    error: null
};

const slice = createSlice({
    name: 'items',
    initialState,
    reducers: {
        setItemPreview(state, action: PayloadAction<any>) {
            state.itemPreview = action.payload;
        },
        unSetItemPreview(state) {
            state.itemPreview = undefined;
        },
        unsetSelectedItem(state) {
            state.selectedItem = undefined;
        },
        unsetItemError(state) {
            state.error = null;
        },
        unsetItems(state) {
            state.items = [];
        },
        unsetDeeplinkOptions(state) {
            state.deeplinkOptions = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchItems.fulfilled, (state, action: any) => {
                state.items = action.payload.items;
                state.error = null;
                state.loading = false;
                state.totalResults = action.payload.totalResults;
            })
            .addCase(fetchItems.rejected, (state, action: any) => {
                state.items = [];
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(fetchItems.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(fetchAllItems.fulfilled, (state, action: any) => {
                state.allItems = action.payload.items;
                state.error = null;
                state.loadingAllItems = false;
            })
            .addCase(fetchAllItems.rejected, (state, action: any) => {
                state.allItems = [];
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loadingAllItems = false;
            })
            .addCase(fetchAllItems.pending, (state, _action) => {
                state.loadingAllItems = true;
            })
            .addCase(fetchItem.fulfilled, (state, action: any) => {
                state.selectedItem = action.payload.item;
                state.error = null;
                state.selectedItemLoading = false;
            })
            .addCase(fetchItem.rejected, (state, action: any) => {
                state.selectedItem = undefined;
                state.error = { ...action.payload.error, status: action.payload.status };
                state.selectedItemLoading = false;
            })
            .addCase(fetchItem.pending, (state, _action) => {
                state.selectedItemLoading = true;
            })
            .addCase(fetchContentSourceTypes.fulfilled, (state, action: any) => {
                state.contentSourceTypes = action.payload.contentSourceTypes;
                state.error = null;
                state.loading = false;
            })
            .addCase(fetchContentSourceTypes.rejected, (state, action: any) => {
                state.contentSourceTypes = [];
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(fetchItemTypes.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(fetchItemTypes.fulfilled, (state, action: any) => {
                state.storeItemTypes = action.payload.itemTypes;
                state.error = null;
                state.loading = false;
            })
            .addCase(fetchItemTypes.rejected, (state, action: any) => {
                state.storeItemTypes = [];
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(fetchContentActionTypes.pending, (state, _action) => {
                state.actionTypeLoading = true;
            })
            .addCase(fetchContentActionTypes.fulfilled, (state, action: any) => {
                state.contentActionTypes = action.payload.contentActionTypes;
                state.actionTypeError = null;
                state.actionTypeLoading = false;
            })
            .addCase(fetchContentActionTypes.rejected, (state, action: any) => {
                state.contentActionTypes = [];
                state.actionTypeError = { ...action.payload.error, status: action.payload.status };
                state.actionTypeLoading = false;
            })
            .addCase(fetchDeeplinkOptions.pending, (state, _action) => {
                state.deeplinkOptionsLoading = true;
            })
            .addCase(fetchDeeplinkOptions.fulfilled, (state, action: any) => {
                state.deeplinkOptions = action.payload.deeplinkOptions;
                state.deeplinkOptionsLoading = false;
            })
            .addCase(fetchDeeplinkOptions.rejected, (state, _action: any) => {
                state.deeplinkOptions = [];
                state.actionTypeLoading = false;
            })
            .addCase(fetchContentSourceTypes.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(createItem.fulfilled, (state, _action) => {
                state.createOrModifyLoading = false;
            })
            .addCase(createItem.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.createOrModifyLoading = false;
            })
            .addCase(createItem.pending, (state, _action) => {
                state.createOrModifyLoading = true;
            })
            .addCase(updateItem.fulfilled, (state, _action) => {
                state.createOrModifyLoading = false;
            })
            .addCase(updateItem.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.createOrModifyLoading = false;
            })
            .addCase(updateItem.pending, (state, _action) => {
                state.createOrModifyLoading = true;
            })
            .addCase(deleteItem.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(deleteItem.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(deleteItem.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(publishItem.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(publishItem.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(publishItem.pending, (state, _action) => {
                state.loading = true;
            })
            .addCase(deleteItemList.fulfilled, (state, _action) => {
                state.loading = false;
            })
            .addCase(deleteItemList.rejected, (state, action: any) => {
                state.error = { ...action.payload.error, status: action.payload.status };
                state.loading = false;
            })
            .addCase(deleteItemList.pending, (state, _action) => {
                state.loading = true;
            });
    }
});

export default slice.reducer;
export const { unSetItemPreview, unsetItemError, setItemPreview, unsetSelectedItem, unsetItems, unsetDeeplinkOptions } = slice.actions;
