const pixelsUnit = 'px' as 'px';

export const UsersTableSizes = {
    fullName: { $width: 436, $wDesktopL: 214, $wDesktopS: 96, $wTab: 80, $um: pixelsUnit },
    tenants: { $width: 200, $wDesktopL: 128, $wDesktopS: 80, $wTab: 76, $um: pixelsUnit, $fixedSize: true },
    Project: { $width: 320, $wDesktopL: 244, $wDesktopS: 206, $wTab: 74, $um: pixelsUnit, $fixedSize: true },
    'User-Group': { $width: 320, $wDesktopL: 266, $wDesktopS: 206, $wTab: 82, $um: pixelsUnit, $fixedSize: true },
    actions: { wDesktopS: 92, $wTab: 56, $um: pixelsUnit }
};

export const GroupsTableSizes = {
    name: { $width: 480, $wDesktopL: 278, $wDesktopS: 160, $wMobL: 80, $um: pixelsUnit },
    tenant: { $width: 200, $wDesktopS: 80, $wTab: 76, $um: pixelsUnit, $fixedSize: true },
    projects: { $width: 320, $wDesktopS: 114, $um: pixelsUnit, $fixedSize: true },
    users: { $width: 104, $wTab: 56, $um: pixelsUnit, $fixedSize: true },
    actions: { $wTab: 92, $um: pixelsUnit }
};

export const TenantsTableSizes = {
    name: { $width: 408, $wDesktopL: 408, $wDesktopS: 186, $wTab: 120, $um: pixelsUnit },
    key: { $width: 480, $wDesktopL: 200, $wDesktopS: 110, $wTab: 80, $um: pixelsUnit, $fixedSize: true },
    tenantAdmins: { $width: 206, $wDesktopL: 200, $wDesktopS: 110, $wTab: 76, $um: pixelsUnit, $fixedSize: true },
    lastModified: { $width: 108, $um: pixelsUnit, $fixedSize: true },
    actions: { $wTab: 56, $um: pixelsUnit }
};

export const DisabledTenantsTableSizes = {
    name: { $width: 440, $wDesktopL: 220, $wDesktopS: 116, $wTab: 120, $um: pixelsUnit },
    placed: { $width: 440, $wDesktopL: 262, $wDesktopS: 212, $wTab: 100, $um: pixelsUnit, $fixedSize: true },
    key: { $width: 100, $wDesktopL: 80, $wDesktopS: 48, $wTab: 36, $um: pixelsUnit, $fixedSize: true },
    tenant_admins: { $width: 200, $wDesktopL: 140, $wDesktopS: 100, $wTab: 56, $um: pixelsUnit, $fixedSize: true },
    lastModified: { $width: 108, $um: pixelsUnit, $fixedSize: true },
    actions: { $wTab: 56, $um: pixelsUnit }
};

export const ProjectsTableSizes = {
    name: { $width: 408, $wDesktopL: 408, $wDesktopS: 186, $wTab: 120, $um: pixelsUnit },
    key: { $width: 480, $wDesktopL: 200, $wDesktopS: 110, $wTab: 80, $um: pixelsUnit, $fixedSize: true },
    projectLeads: { $width: 206, $wDesktopL: 200, $wDesktopS: 110, $wTab: 76, $um: pixelsUnit, $fixedSize: true },
    lastModified: { $width: 108, $um: pixelsUnit, $fixedSize: true },
    actions: { wDesktopS: 92, $wTab: 56, $um: pixelsUnit }
};

// OBJECTS
export const PagesTableSizes = {
    name: { $width: 360, $wDesktopL: 210, $wDesktopS: 104, $um: pixelsUnit },
    placed: { $width: 333, $wDesktopL: 186, $wDesktop: 207, $wTab: 164, $um: pixelsUnit, $fixedSize: true },
    conditions: { $width: 398, $wDesktopL: 198, $wDesktopS: 167, $wTab: 119, $um: pixelsUnit, $fixedSize: true },
    lastModified: { $width: 138, $um: pixelsUnit, $fixedSize: true },
    actions: { $wTab: 92, $um: pixelsUnit }
};

export const ModulesTableSizes = {
    template: { $width: 90, $um: pixelsUnit, $fixedSize: true },
    name: { $width: 308, $wDesktopL: 136, $wDesktopS: 76, $um: pixelsUnit },
    content_source: { $width: 320, $wDesktopL: 151, $wDesktopS: 90, $um: pixelsUnit, $fixedSize: true },
    placed: { $width: 334, $wDesktopL: 126, $wDesktopS: 124, $wTab: 32, $um: pixelsUnit, $fixedSize: true },
    conditions: { $width: 320, $wDesktopL: 166, $wTab: 32, $um: pixelsUnit, $fixedSize: true },
    lastModified: { $width: 118, $um: pixelsUnit, $fixedSize: true },
    actions: { $wTab: 92, $um: pixelsUnit }
};

export const ItemsTableSizes = {
    name: { $width: 296, $wDesktopL: 218, $wDesktopS: 120, $wTab: 89, $um: pixelsUnit },
    source: { $width: 211, $wDesktopL: 132, $wDesktopS: 80, $um: pixelsUnit, $fixedSize: true },
    maxNumberOfItems: { $wDesktopL: 44, $um: pixelsUnit, $fixedSize: true },
    placed: { $width: 334, $wDesktopL: 126, $wDesktopS: 124, $wTab: 32, $um: pixelsUnit, $fixedSize: true },
    conditions: { $width: 190, $wDesktopS: 150, $wTab: 32, $um: pixelsUnit, $fixedSize: true },
    lastModified: { $width: 138, $um: pixelsUnit, $fixedSize: true },
    actions: { $wTab: 92, $um: pixelsUnit }
};

export const MenusTableSizes = {
    name: { $width: 480, $wDesktopL: 186, $wDesktopS: 170, $wTab: 100, $um: pixelsUnit },
    no_items: { $width: 80, $wDesktopS: 32, $um: pixelsUnit, $fixedSize: true },
    conditions: { $width: 240, $wDesktopL: 150, $wDesktopS: 32, $um: pixelsUnit, $fixedSize: true },
    placed: { $width: 240, $wDesktopL: 150, $wDesktopS: 32, $um: pixelsUnit, $fixedSize: true },
    bind_to: { $width: 130, $wDesktopL: 103, $wTab: 80, $um: pixelsUnit, $fixedSize: true },
    lastModified: { $width: 156, $wDesktopS: 108, $um: pixelsUnit, $fixedSize: true },
    actions: { $wTab: 92, $um: pixelsUnit }
};

export const PageStylesTableSizes = {
    name: { $width: 480, $wDesktopL: 240, $wDesktopS: 160, $wTab: 120, $um: pixelsUnit },
    placed: { $width: 650, $wDesktopL: 424, $wDesktopS: 200, $wTab: 42, $um: pixelsUnit, $fixedSize: true },
    isDefault: { $width: 126, $wDesktopL: 82, $wDesktopS: 77, $wTab: 40, $um: pixelsUnit, $fixedSize: true },
    lastModified: { $width: 156, $wTab: 108, $um: pixelsUnit, $fixedSize: true },
    actions: { $wTab: 92, $um: pixelsUnit }
};

export const SettingsTableSizes = {
    name: { $width: 480, $wDesktopL: 320, $wDesktopS: 200, $wTab: 120, $um: pixelsUnit },
    conditions: { $width: 293, $wDesktopL: 190, $wDesktopS: 136, $wTab: 32, $um: pixelsUnit, $fixedSize: true },
    placed: { $width: 407, $wDesktopL: 190, $wDesktopS: 136, $wTab: 32, $um: pixelsUnit, $fixedSize: true },
    lastModified: { $width: 118, $um: pixelsUnit, $fixedSize: true },
    actions: { $wTab: 92, $um: pixelsUnit }
};

export const LanguagesTableSizes = {
    name: { $wTab: 82, $um: pixelsUnit },
    default: { $wTab: 32, $um: pixelsUnit, $fixedSize: true },
    translationProgress: { $wTab: 54, $um: pixelsUnit, $fixedSize: true },
    lastModified: { $width: 108, $um: pixelsUnit, $fixedSize: true },
    actions: { $wTab: 56, $um: pixelsUnit }
};

export const UnpublishedTableSizes = {
    name: { $width: 558, $wDesktopL: 350, $wDesktopS: 195, $wTab: 140, $um: pixelsUnit },
    objectType: { $width: 300, $wDesktopL: 100, $wDesktopS: 65, $um: pixelsUnit, $fixedSize: true },
    placed: { $width: 350, $wDesktopS: 246, $wTab: 32, $um: pixelsUnit, $fixedSize: true },
    lastModified: { $width: 108, $um: pixelsUnit, $fixedSize: true },
    actions: { $wTab: 56, $um: pixelsUnit }
};

export const AudiencesTableSizes = {
    select_group: { $width: 320, $wTab: 185, $um: pixelsUnit },
    name: { $width: 400, $wDesktopL: 248, $wDesktopS: 220, $wTab: 82, $um: pixelsUnit },
    ['targetGroup.name']: { $width: 220, $wDesktopL: 260, $wDesktopS: 128, $wTab: 58, $um: pixelsUnit, $fixedSize: true },
    placed: { $width: 560, $wDesktopL: 346, $wDesktopS: 216, $wTab: 42, $um: pixelsUnit, $fixedSize: true },
    lastModified: { $width: 156, $wDesktopS: 128, $um: pixelsUnit, $fixedSize: true },
    actions: { $wTab: 56, $um: pixelsUnit }
};

export const ConditionsTableSizes = {
    name: { $width: 400, $wDesktopL: 248, $wDesktopS: 240, $wTab: 120, $um: pixelsUnit },
    state: { $width: 56, $wTab: 48, $um: pixelsUnit, $fixedSize: true },
    placed: { $width: 760, $wDesktopL: 448, $wDesktopS: 171, $wTab: 160, $um: pixelsUnit, $fixedSize: true },
    lastModified: { $width: 156, $wTab: 108, $um: pixelsUnit, $fixedSize: true },
    actions: { $wTab: 56, $um: pixelsUnit }
};

export const GroupAudiencesTableSizes = {
    name: { $width: 400, $wTab: 120, $um: pixelsUnit },
    lastModified: { $width: 156, $um: pixelsUnit, $fixedSize: true },
    actions: { $width: 192, $wTab: 56, $um: pixelsUnit }
};

export const SourcesTableSizes = {
    name: { $wTab: 72, $um: pixelsUnit },
    placed: { $width: 613, $wDesktopL: 443, $wDesktopS: 261, $wTab: 120, $um: pixelsUnit, $fixedSize: true },
    lastModified: { $width: 156, $wTab: 108, $um: pixelsUnit, $fixedSize: true },
    actions: { $width: 92, $wDesktopL: 62, $wTab: 56, $um: pixelsUnit }
};

export const AssetsTableSizes = {
    thumbnail: { $width: 210, $um: pixelsUnit, $fixedSize: true },
    name: { $width: 500, $wDesktopL: 348, $wDesktopS: 140, $wTab: 76, $um: pixelsUnit },
    tags: { $width: 490, $wDesktopL: 348, $wDesktopS: 120, $wTab: 52, $um: pixelsUnit },
    status: { $width: 297, $wDesktopS: 165, $wTab: 90, $um: pixelsUnit, $fixedSize: true },
    lastModified: { $width: 156, $wTab: 108, $um: pixelsUnit, $fixedSize: true },
    actions: { $wTab: 92, $um: pixelsUnit }
};

// TEMPLATES
export const ModulesTemplatesTableSizes = {
    icon: { $width: 48, $um: pixelsUnit, $fixedSize: true },
    template: { $width: 90, $um: pixelsUnit, $fixedSize: true },
    name: { $width: 308, $wDesktopL: 136, $wDesktopS: 114, $wTab: 76, $um: pixelsUnit },
    placed: { $width: 664, $wDesktopL: 320, $wDesktopS: 166, $wTab: 32, $um: pixelsUnit, $fixedSize: true },
    lastModified: { $width: 108, $um: pixelsUnit, $fixedSize: true },
    actions: { $wTab: 92, $um: pixelsUnit }
};

export const ItemsTemplatesTableSizes = {
    icon: { $width: 48, $um: pixelsUnit, $fixedSize: true },
    name: { $width: 308, $wDesktopL: 136, $wDesktopS: 130, $wTab: 89, $um: pixelsUnit },
    source: { $width: 211, $wDesktopL: 95, $um: pixelsUnit, $fixedSize: true },
    placed: { $width: 664, $wDesktopL: 320, $wDesktopS: 166, $wTab: 32, $um: pixelsUnit, $fixedSize: true },
    lastModified: { $width: 108, $um: pixelsUnit, $fixedSize: true },
    actions: { $wTab: 92, $um: pixelsUnit }
};

export const SourcesTemplatesTableSizes = {
    name: { $width: 308, $wDesktopL: 240, $wdesktopS: 260, $wTab: 120, $um: pixelsUnit },
    placed: { $width: 577, $wDesktopL: 334, $wDesktopS: 225, $wTab: 88, $um: pixelsUnit, $fixedSize: true },
    lastModified: { $width: 156, $wTab: 108, $um: pixelsUnit, $fixedSize: true },
    actions: { $wTab: 92, $um: pixelsUnit }
};

export const PageStyleTemplatesTableSizes = {
    icon: { $width: 48, $um: pixelsUnit, $fixedSize: true },
    name: { $width: 480, $wDesktopL: 344, $wDesktopS: 160, $wTab: 89, $um: pixelsUnit },
    placed: { $width: 560, $wDesktopL: 344, $wDesktopS: 200, $wTab: 42, $um: pixelsUnit, $fixedSize: true },
    lastModified: { $width: 108, $um: pixelsUnit, $fixedSize: true },
    actions: { $wTab: 92, $um: pixelsUnit }
};
