import styled from 'styled-components';
import { devices, SCREEN_SIZES } from '../../../../style/styled-components/reusable.css';
import getTheme, { fontSizes, fontSizesCSS } from '../../../../style/themes/theme';
import { ActionIconHolder as Holder, ActionWrapper } from '../../../common/Actions/Actions.css';
import { TitleTableRow } from '../../../common/Table/Table.css';

const theme: any = getTheme();

export const ActionIconHolder = styled.div``;

export const GridView = styled.div`
    margin-bottom: 24px;
    padding: 24px;
    background: black;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
`;

export const ListView = styled.div``;

export const AudienceContainer = styled.div`
    max-width: 360px;
    height: 104px;
    display: flex;
    align-items: center;
`;

export const AudienceTemplateWrapper = styled.div`
    display: flex;
    gap: 16px;
    align-items: center;
    padding-right: 24px;
    box-sizing: border-box;
`;

export const AudienceTemplateName = styled.div`
    max-width: 360px;
    width: 100%;
`;
export const AudienceTemplateDescription = styled.div`
    max-width: 560px;
    width: 100%;
`;

export const AudienceTitleContainer = styled.div`
    gap: 8px;
    display: flex;
    min-width: 400px;
    align-items: center;
    ${fontSizes.Body1}
    cursor: pointer;
    ${devices.tablet} {
        min-width: 120px;
    }
`;

export const AudienceDescriptionContainer = styled.div`
    margin-top: 10px;
    color: ${theme.palette.background.hovered};
`;

export const LastModified = styled.div`
    ${fontSizesCSS.Body1};
    display: flex;
    margin-left: auto;
    align-items: center;
    cursor: pointer;
    gap: 4px;
    img {
        min-width: 32px;
        width: 32px;
        height: 32px;
        object-position: center;
        object-fit: cover;
        margin-left: auto;
        border-radius: 50%;
    }

    svg {
        display: none;
    }
    &:hover {
        svg {
            display: block;
        }
    }

    @media (max-width: ${SCREEN_SIZES.DESKTOP_S}) and (min-width: ${SCREEN_SIZES.MOBILE_L}px) {
        img {
            display: none;
        }
    }
`;

export const AudienceListContainer = styled.div`
    height: 76px;
    background: ${theme.palette.white.main};
    border-top: 1px solid ${theme.palette.background.pressed};
    display: flex;
    align-items: center;
    padding-left: 24px;

    &:hover {
        background: ${theme.palette.background.active};
        ${ActionWrapper} {
            ${Holder} {
                display: inline;
                &:last-child {
                    display: none;
                }
            }
        }
    }
`;

export const ItemName = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    ${fontSizesCSS.Caption2};
    span {
        line-height: 0px;
    }
    svg {
        margin-left: 0px;
        width: 40px;
        height: 40px;
    }
`;
export const ItemType = styled.div`
    ${fontSizesCSS.Caption3};
`;

export const ItemIcon = styled.div<{ $backgroundColor?: string }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    background: ${({ $backgroundColor }) => $backgroundColor || theme.palette.success.main};

    svg {
        width: 40px;
        height: 40px;
    }
`;

export const ListItemContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    ${ItemName} {
        width: 200px;
    }

    ${ItemIcon} {
        width: 40px;
        height: 40px;

        svg {
            width: 40px;
            height: 40px;
        }
    }
`;

export const ItemAddIcon = styled.div`
    position: absolute;
    right: 16px;
    top: 22px;
`;

export const ItemActions = styled.div`
    position: absolute;
    display: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid ${theme.palette.grey.light};
    border-radius: 4px;
    background-color: ${theme.palette.background.hovered};
    align-items: center;
    justify-content: center;
    ${ActionIconHolder} {
        padding: 5px;
    }
`;

export const ItemCard = styled.div`
    position: relative;
    width: 253px;
    ${devices.tablet} {
        width: 250px;
    }
    ${devices.mobileL} {
        width: 260px;
    }
    ${devices.mobileS} {
        width: 100%;
    }
    height: 72px;
    padding: 16px;
    background-color: ${theme.palette.white.main};
    display: flex;
    align-items: center;
    border-radius: 4px;
    box-sizing: border-box;
    border: 1px solid ${theme.palette.grey.light};
    gap: 8px;
    span {
        line-height: 0px;
    }
    svg {
        &:hover {
            cursor: pointer;
        }
    }

    &:hover {
        ${ItemActions} {
            display: flex;
        }
    }
`;

export const ListAddNew = styled(ListItemContainer)`
    &:hover {
        cursor: pointer;
    }
    svg {
        width: 24px;
        height: 24px;
    }
`;

export const ItemCardAdd = styled(ItemCard)`
    justify-content: space-between;
`;

export const AudiencesWrapper = styled.div<{ $isList?: boolean }>`
    margin: ${({ $isList }) => ($isList ? '36px 0 24px 0' : '0 0 24px 0')};

    .MuiTableBody-root {
        .MuiTableRow-root:not(${TitleTableRow}, :last-child) {
            height: 104px;
        }
    }
`;
